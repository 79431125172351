
// Colors
$color-one: rgb( 190, 226, 229 );
$color-two: rgb( 0, 168, 187 );
$color-three: rgb( 71, 188, 204 );
$color-four: rgb( 48, 47, 126 );
$color-light: #fff;

$color-one-light: rgba( 190, 226, 229, 0.75 );
$color-one-lightest: rgba( 190, 226, 229, 0.25 );
$color-three-light: rgba( 71, 188, 204, 0.75 );
$color-three-lighter: rgba( 71, 188, 204, 0.5 );
$color-three-lightest: rgba( 71, 188, 204, 0.25 );
$color-four-light: rgba( 48, 47, 126, 0.75 );
$color-four-lighter: rgba( 48, 47, 126, 0.5 );

$color-text-base: #333333;

// tranisitions
$ease-in-out-slow: all 500ms ease-in-out;

// Screen Sizes
$screen-small: 576px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-xl: 1200px;

/********************************************************
* Mixins
*********************************************************/

@mixin transition($in) {
	transition:$in;
}

@mixin transform($in) {
	transform:$in;
}

/********************************************************
* Globals
*********************************************************/

img{
  width: 100%;
  max-width: 100%;
  height: auto;
}

.container-fluid{
  max-width: 1500px;
}

.screen-reader{
  visibility: hidden;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.bullets{
  list-style-type: circle;
  padding-left: 2em;
  li{
    margin: 0.25em 0;
  }
}

ol{
  padding-left: 20px;
}

label{
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.container-fluid{
  max-width: 1500px;
}

/********************************************************
* Type
*********************************************************/
h1, h2, h3, h4, h5, h6{
  color: $color-four;
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
}

h1{
  letter-spacing: 1px;
}

h2{
  font-size: 1.5em;
}

a{
  color: $color-four-light;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: $color-four;
  }
}

.text-muted{
  color: $color-one-light !important;
}

/********************************************************
* Navbar
*********************************************************/


body{
  &.test{
    header{
      display: none;
    }
    footer{
      display: none;
    }
  }
  &.home{
    header{
      .navbar {
        .navbar-brand{
          img#logo{
            height: 34px;
            max-height: 34px;
            width: auto;
          }
        }
        &.navbar-bg-one{
          .navbar-toggler{
            color: $color-four;
            border-color: $color-four;
            &:focus{
              outline: none;
            }
          }
        }
      }
    }
    footer{
      height: 30px;
      p{
        margin-bottom: 0.75em;
      } 
    }
  }
}

.navbar-form{
  padding: 1em 0em;
  .navbar-brand{
    img#logo{
      height: 34px;
      max-height: 34px;
      width: auto;
    }
  }
}

.modal#navModal{
  background: $color-two;
  padding: 0;
  margin: 0;
  .modal-dialog{
    height: 100%;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    .modal-content{
      background: transparent;
      border: none;
      .modal-header{
        border-bottom: none;
        button.close{
          font-size: 2em;
          opacity: 0.8;
          span{
            color: #ffffff;
          }
          &:focus{
            outline: 0;
          }
        }
      }
      .modal-body{
        li.nav-item{
          a{
            color: #ffffff;
            font-size: 1.2em;
            margin-bottom: 10px;
            &:hover{
              color: $color-four;
              transition: all 500ms ease-in-out;
            }
          }
          @media ( min-width: $screen-medium ){
            font-size: 2.2em;
          }
        }
      }
    }
  }
}

/********************************************************
* Main
*********************************************************/
.grid{
  .grid-item{

    padding: 0 5px;

    @media( min-width: $screen-medium){
      padding: 0 15px;
    }

    a{
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      color: $color-light;
      background: $color-three;
      border: 2px solid $color-three;
      border-radius: 0.5em;
      overflow: hidden;
      i{
        color: $color-four;
      }
      h2{
        color: $color-light;
        font-weight: 400;
        margin-top: 0.5em;
        letter-spacing: 1px;
        font-size: 1.25em;
      }
      p.text-info{
        color: $color-text-base !important;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        white-space: nowrap;
      }
      span.btn{
        padding: 0.4em 0.75em;
        color: $color-four !important;
        font-size: 12px;
        background: none;
        border: 1px solid white;
      }
    }
  }
}

/********************************************************
* Flu Test
*********************************************************/
.steps{
  position: relative;
  display: none;
}
#step-1{
  display: block;
}
button#submit{
  display: none;
}

.questions{
  
  .radio-ctrl{
    label{
      cursor: pointer;
    }
  }

  .slider{
    margin-bottom: 3em;
  }
  
  .rslider {
    margin: 14px 0 24px 0;
  
    .caps {
      margin-top: 12px;
      position: relative;
      width: 100%;
      font-size: 12px;
    }
  
    .cap1, .cap2, .cap3, .cap4, .cap5 {
      display: inline;
      position: absolute;
    }
  
  }//rslider

}

#review{
  .qrow{
    position: relative;
    width: 100%;
    margin-bottom: 2em;
    p.q{
      margin: 0.5em;
    }
    p.answer{
      text-transform: capitalize;
      font-weight: 700;
    }
  }
}

/********************************************************
* Symptom Checker
*********************************************************/
.symptomchecker {
  .temp-result{
    font-size: 5em;
    margin-bottom: 0.25em;
  }

  .option-row{
    max-width: 40%;
    label{
      font-size: 0.8em;
    }
  }

}


/********************************************************
* Flu Tips
*********************************************************/
.card-flip{
  width: 100%;
  height: 400px;

  .content{
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

.more {
  display: none;
  &:checked ~ .content{
    transform: rotateY(180deg);
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

.front, .back{
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
  border-radius: 6px;
  
  .inner{
    height: 100%;
		padding: 1.5em;
    transform: translateZ(80px) scale(1);
  }
}

.front{

  i{
    color: $color-light;
  }

  &:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 6px;
    backface-visibility: hidden;
    background: $color-three;
  }

  &.secondary{
    &:after{
      background: $color-two;
    }
  }

  h2 {
    color: $color-light;
  }

}

.back{
  transform: rotateY(180deg);
	background-color: #fff;
  border: 2px solid $color-three-light;

  .description {
    height: 75%;
    width: 100%;
    font-size: 0.85em;
    border-radius: 5px;
    line-height: 1.4em;
    overflow: auto;
	}

	.title,
	.icon {
		color: $color-two;
	}

	.title {
    width: 90%;
	}

  .icon{
    width: 10%;
    text-align: right;
  }

}

.button {
  line-height: 2.9em;
  text-transform: uppercase;
  color: $color-light;
  cursor: pointer;
  display: block;
  padding: 0 1.5em;
  height: 3em;
  min-width: 3em;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  backface-visibility: hidden;
  transition: .3s ease-in-out;

	&:hover {
		background-color: #fff;
		color: $color-three;
	}

	&.return {
		line-height: 3em;
		color: $color-three;
		border-color: $color-three;
		text-shadow: none;
		&:hover {
			background-color: $color-three;
			color: #fff;
			box-shadow: none;
		}
	}
}

::-webkit-scrollbar {
	width: 2.5px;
}
::-webkit-scrollbar-track {
	background: $color-one-lightest;
}
::-webkit-scrollbar-thumb {
	background: $color-four;
}
::-webkit-scrollbar-thumb:hover {
	background: $color-one;
}

/********************************************************
* Rangers Slider
*********************************************************/
.progress-wrapper{
  margin-top: 1em;
  margin-bottom: 2em;

  a{
    font-size: 2em;
  }

  #formProgress.progress{
    
      
      background-color: rgba( $color-four, 0.25);
      
      .progress-bar-flutest{
        background: linear-gradient(45deg,rgba($color-four,.15) 25%,transparent 25%,transparent 50%,rgba($color-four,.15) 50%,rgba($color-four,.15) 75%,transparent 75%,transparent);
        background-size: 1rem 1rem;
      }
    }
  
}

.rangeslider,
.rangeslider__fill {
  background: $color-two;
  display: block;
  height: 4px;
  width: 100%;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.rangeslider {
  position: relative;
}

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  background-color: $color-three;
  position: absolute;
  top: 0;
}

.rangeslider__handle {
  background-color: $color-four;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


input[type="range"]:focus + .rangeslider .rangeslider__handle {
  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}

/********************************************************
* form
*********************************************************/
.form-wrapper{
  background: white;
  max-width: 550px;
  margin: 0 auto;
  overflow: hidden;

  .form-navigation{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

}

.option-row{
  position: relative;
  width: 49%;
  min-width: 120px;
  background-color: rgba( $color-four, 0.25);
  // border-radius: 0.2em;
  overflow: hidden;

  label {
    padding-top: 1em;
    padding-right: 3em;
    padding-bottom: 1em;
    padding-left: 1em;
    display: block;
    text-align: left;
    color: #333;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: $color-three;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: '';
      border: 2px solid #D1D7DC;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #ffffff;
    border-radius: inherit;
    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: $color-four;
      border-color: $color-four;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

.test-section{
  display: none;
  width: 100%;
  margin: 0 auto;
  h4{
    height: 140px;
    @media( min-width: $screen-small ){
      height: 90px;
    }
  }
  .option-wrapper{
    margin-top: 2em;
    margin-bottom: 2em;
  }

  @media ( min-width: $screen-small ){
    &#section-1{
      min-height: 178px;
    }
  }

}

.results-wrapper{
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

#valError{
  visibility: hidden;
  width: 100%;
  max-width: 500px;
}

.loader-wrapper{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 2;



  svg#loader{
    zoom: 2;
    path{
      fill: $color-three;
    }
  }
}

.flu-check{
  .rslider{
    .temp-result{
      font-size: 5em;
      margin-bottom: 0.25em;
    }
  }
}

/********************************************************
* Buttons
*********************************************************/
.btn{
  display: inline-block;
  padding: 0.75em;
  border-radius: 0.25em;
  font-size: 1em;
  font-weight: 400;
  cursor: pointer;
  &:focus{
    box-shadow: none;
    outline: 0;
  }
}

.btn-default{
  background: $color-four;
  color: $color-light !important;
  &:hover{
    background: $color-four-light;
    color: $color-light; 
    transition: $ease-in-out-slow; 
    box-shadow: inset 0px 0px 15px 5px rgba( 0,0,0,0.25);
  }
}

.btn-secondary{
  color: $color-four;
  background: transparent;
  border: 1px solid $color-four;
  &:hover{
    color: $color-light !important;
    transition: $ease-in-out-slow; 
    background: $color-four;
  }
}

.btn-hover-text{
  white-space: nowrap;
  max-width: 50px;
  overflow: hidden;
  opacity: 0.5;
  span{
    visibility: hidden;
  }
  &:hover{
    max-width: 100%;
    span{
      visibility: visible;
    }
  }
}

.btn-lg{
  padding-left: 2em;
  padding-right: 2em;
}

.btn-form-nav{
  width: 100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-left: 0px;
  margin-right: 0px;
  border: 0px;
  border-radius: 0px;
  @media( min-width: $screen-small  ){
    width: 49%;
  }

  &.btn-next{
    order: 1;
    background-color: $color-two;
    color: #fff;
    @media ( min-width: $screen-small ){
      order: 2;
    }
  }
  &.btn-prev{
    order: 2;
    @media ( min-width: $screen-small ){
      order: 1;
    }
  }
}

.btn-social{
  li{
    position: relative;
    display: inline-block;
    width: 3em;
    height: 3em;
    border: 2px solid $color-four;
    border-radius: 100%;
    margin: 1em 0.5em;
    cursor: pointer;
    transition: border-radius .5s, transform .5s, border-color 1s;
    i{
      color: $color-four;
      font-size: 1.5em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color .5s;
    }
    &:hover{
      border-radius: 10%;
      transform: rotate(360deg);
    }
    &.facebook{
      &:hover{
        border-color: #3b5998;
        i{
          color: #3b5998;
        }
      }
    }
    &.twitter{
      &:hover{
        border-color: #55acee;
        i{
          color: #55acee;
        }
      }
    }
    &.linkedin{
      &:hover{
        border-color: #0077b5;
        i{
          color: #0077b5;
        }
      }
    }
    &.whatsapp{
      &:hover{
        border-color: #25D366;
        i{
          color: #25D366;
        }
      }
    }
  }
}

/********************************************************
* Special
*********************************************************/
span.ball{
  background: rgba( $color-two, 0.25 );
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: 1em 0.25em;
  border-radius: 50%;
}

/********************************************************
* faqs
*********************************************************/
.faqs{
  .card{
    button{
      text-decoration: none;
      padding: 0.75em 0;
    }
    .cite{
      font-size: 12px;
      color: rgba( $color-four, 0.25);
      &:hover{
        transition: all 500ms ease-in-out;
        color: rgba( $color-four, 0.85);
      }
    }
    .card-body{
      .card-inner{
        border-left: 2px solid $color-one;
        padding-left: 1.25em;
      }
    }
  }
}


/********************************************************
* Media Queries
*********************************************************/
@media ( min-width: $screen-medium ) {
  
  h2{
    font-size: 2em;
  }

  .grid{

    .floating-img{
      position: absolute;
      width: 250px;
      height: auto;
      top: 50%;
      left: 50%;
      margin-left: -125px;
      margin-top: -177px;
    }

    .grid-item{ 

      a{
        @include transition(all 0.5s ease);

        h2{
          color: $color-four;
          font-size: 2em;
          font-weight: 700;
        }

        p.text-info{
          position: relative;
          display: block;
          visibility: visible;
          white-space: normal;
          height: 100%;
          width: 100%;
        }

        span.btn{
          padding: 0.75em 1em;
          font-size: 1em;
          background: transparent;
          border-color: $color-four;
          color: $color-four;
        }

        .wipe{
          width: 125%;
          height: 20px;
          position: absolute;
          left: -130%;
          top: 45%;
          background:#fff;
          @include transform(rotateZ(45deg));
        }
        
        &:hover{
          background-color: $color-three;
          border-color: $color-three;
          color: #fff;
          opacity: 1;
          h2{
            color: $color-light;
          }
          p{
            color: $color-light !important;
          }
          .wipe{
            @include transition(all 0.75s ease);
            left: 550px;
          }
          span.btn{
            background: $color-light;
            border-color: $color-light;
          }
        }
      }
    }
  }

  .btn-social{
    li{
      width: 4em;
      height: 4em;
    }
  }
}